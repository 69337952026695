<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('user.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('user.pageTitle'),
                        href: '/admin/users'
                    },
                    {
                        text: $t('edit'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-4 tw-mb-5">
                        <UserAccount v-model="model" :validate="errors" class="tw-h-full" />
                    </div>
                    <div class="col-md-8 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <Permission v-model="model" class="tw-h-full" />
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 tw-mb-5">
                        <UserBranch
                            v-model="model"
                            :validate="errors"
                            class="tw-h-56"
                        />
                    </div>
                    <div class="col-md-4 tw-mb-5">
                        <UserRole
                            v-model="model"
                            :validate="errors"
                            class="tw-h-56"
                        />
                    </div>
                    <div class="col-md-4 tw-mb-5">
                        <AllowIp
                            v-model="model"
                            :validate="errors"
                            class="tw-h-56"
                        />
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'user' })"
                class="btn-gray"
                >{{ $t("cancel") }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t("update") }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from "@/config/AppOptions.vue";
import { mapActions, mapState } from "vuex";
import { Errors } from "form-backend-validation";
import { isEmpty, transform } from "lodash";
import UserAccount from "./components/account.vue";
import UserRole from "./components/role.vue";
import UserBranch from "./components/branch.vue";
import AllowIp from "./components/allow-ip.vue";
import Permission from "./components/permision.vue";

export default {
    name: "userEdit",
    components: {
        UserAccount,
        UserRole,
        UserBranch,
        Permission,
        AllowIp
    },
    data() {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                user_id: 0,
                employee_id: null,
                user_name: "",
                email: "",
                roles: [],
                permissions: [],
                branches: [],
                allow_ip_address: [],
                disable: false,
                password: null,
                password_confirmation: null
            }
        };
    },
    created() {
        AppOptions.appContentFullHeight = true;
        AppOptions.appContentClass = "p-0";
    },
    computed: {
        ...mapState("auth/user", ["edit_data"])
    },
    methods: {
        ...mapActions("auth/user", [
            "getUserRole",
            "getPermissions",
            "find",
            "getBranches",
            "getEmployees"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getUserRole();
            await this.getPermissions();
            await this.getBranches();
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: "error", text: error.message });
            });
            this.setEditData();
            this.loading = false;
        },
        onUpdate() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("auth/user/update", {
                    id: this.model.user_id,
                    ...this.model
                })
                .then(response => {
                    this.notice({ type: "success", text: response.message });
                    this.$router.push({ name: "user" });
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        async setEditData() {
            if (!isEmpty(this.edit_data)) {
                await this.getEmployees({
                    search: this.edit_data.employee
                        ? this.edit_data.employee.employee_name_en
                        : null
                });
                this.model.user_id = this.edit_data.user_id;
                this.model.user_name = this.edit_data.user_name;
                this.model.disable = this.edit_data.disable;
                this.model.employee_id = this.edit_data.employee_id;
                this.model.email = this.edit_data.email;

                this.model.roles = this.edit_data.roles.map(el => el.id);
                this.model.permissions = this.edit_data.permissions.map(
                    el => el.id
                );
                this.model.branches = transform(
                    this.edit_data.branches,
                    (result, branch) => {
                        result[branch.branch_id] = {
                            is_default: branch.pivot.is_default
                        };
                    },
                    {}
                );
                if (this.edit_data.allow_ip_address !== "Any") {
                    this.model.allow_ip_address = this.edit_data.allow_ip_address
                        .split("|")
                        .map(el => ({
                            ip: el
                        }));
                }
            }
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "USER",
                desc: not.text
            });
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchResource();
        });
    },
    beforeRouteLeave(to, from, next) {
        AppOptions.appContentFullHeight = false;
        AppOptions.appContentClass = "";
        this.$store.commit("auth/user/SET_EDIT_DATA", {});
        this.$store.commit("auth/user/CLEAR_RESOURCES");
        next();
    }
};
</script>
